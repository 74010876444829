import AppConfig from 'AppConfig'
import ReactDom from 'react-dom'
import { Provider } from 'react-redux'
import storage from './app/storage'
import PostmRouter from 'cursol/sdk/postm/containers/PostmRouter'
import routes from './app/routes'

;(() => {
  const onReady = () => {
    return ReactDom.render(
      <Provider store={storage()}>
        <PostmRouter origins={AppConfig.claus.allowedOrigins} routes={routes} />
      </Provider>,
      document.querySelector('#app')
    )
  }

  const onDOMLoaded = () => {
    localStorage && localStorage.csdebug && console.log('iClaus: DOM loaded')
    window.removeEventListener('load', onDOMLoaded, false)
    document.removeEventListener('DOMContentLoaded', onDOMLoaded, false)
    onReady()
  }

  if (document.readyState === 'complete') {
    onDOMLoaded()
  } else {
    window.addEventListener('load', onDOMLoaded, false)
    document.addEventListener('DOMContentLoaded', onDOMLoaded, false)
  }
})()
