import { getToken as getTokenAction, refreshToken, signOut, updateToken } from 'cursol/sdk/auth/actions/auth'
import { hasToken, isTokenExpired, getToken, useSessionStorage } from 'cursol/sdk/auth/jwt'

let onTokenReadyHandlers = []
useSessionStorage()

export const getTokenPostm = (payload, responder) => dispatch => {
  if (hasToken() && !isTokenExpired()) {
    responder({
      token: getToken(),
    })

    return
  }

  if (!onTokenReadyHandlers.length) {
    onTokenReadyHandlers.push(responder)
    localStorage && localStorage.csdebug && console.log('iClaus: get token request', payload)

    dispatch(getTokenAction('token', payload)).then(() => {
      localStorage && localStorage.csdebug && console.log('iClaus: get token request resolved', getToken())
      onTokenReadyHandlers.map(responder => {
        responder({
          token: getToken(),
        })
      })
      onTokenReadyHandlers = []
    })
  } else {
    onTokenReadyHandlers.push(responder)
  }
}

export const updateTokenPostm = (payload, responder) => dispatch => {
  localStorage && localStorage.csdebug && console.log('iClaus: update token request resolved', getToken())
  dispatch(updateToken('token', payload)).then(() => {
    localStorage && localStorage.csdebug && console.log('iClaus: update token request resolved', getToken())
    responder({
      token: getToken(),
    })
  })
}

export const refreshTokenPostm = (message, responder) => dispatch => {
  localStorage && localStorage.csdebug && console.log('iClaus: refresh token request resolved', getToken())
  dispatch(refreshToken('refresh')).then(() => {
    localStorage && localStorage.csdebug && console.log('iClaus: refresh token request resolved', getToken())
    responder({
      token: getToken(),
    })
  })
}

export const signOutPostm = (message, responder) => dispatch => {
  dispatch(signOut())

  responder({
    success: true,
  })
}
